const ID_TOKEN = 'token'
const ID_TOKEN_EXPIRATION = 'tokenExpiration'

export const getToken = () => {
  return localStorage.getItem(ID_TOKEN)
}

export const saveToken = (token: string) => {
  localStorage.setItem(ID_TOKEN, token)
}

export const destroyToken = () => {
  localStorage.removeItem(ID_TOKEN)
}

export const getTokenExpiration = () => {
  return localStorage.getItem(ID_TOKEN_EXPIRATION)
}

export const saveTokenExpiration = (tokenExpiration: string) => {
  localStorage.setItem(ID_TOKEN_EXPIRATION, tokenExpiration)
}

export const destroyTokenExpiration = () => {
  localStorage.removeItem(ID_TOKEN_EXPIRATION)
}

export default { getToken, saveToken, destroyToken, getTokenExpiration, saveTokenExpiration, destroyTokenExpiration }
