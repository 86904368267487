









import Vue from 'vue'
import NavigationBar from './components/NavigationBar.vue'

export default Vue.extend({
  name: 'App',

  components: {
    NavigationBar
  },

  data: () => ({
    //
  })
})
