/* eslint-disable  @typescript-eslint/no-explicit-any */
import ApiService from '@/api/api.service'
import { AuthenticationService } from '@/api/AuthenticationService'
import jwtService from '@/api/jwt.service'
import LoginModel from '@/models/LoginModel'
import LoginResponseModel from '@/models/LoginResponseModel'
import router from '@/router'
import { Commit } from 'vuex'
import { CHECK_AUTH, LOGIN, LOGOUT } from '../actions'
import { FETCH_ERROR, SEND_START, PURGE_AUTH, SET_AUTH, SET_ERROR, SET_LOGGED_IN } from '../mutations'

// initial state
const state = {
  user: {
    username: ''
  },
  isLoggedIn: false,
  isLoading: false
}

// getters
const getters = {
  isLoggedIn () {
    return state.isLoggedIn
  }
}

// actions
const actions = {
  [LOGIN] ({ commit }: { commit: Commit }, data: LoginModel) {
    commit(SEND_START)
    AuthenticationService.login(data).then(({ data }) => {
      commit(SET_AUTH, data)
    }).catch(error => commit(FETCH_ERROR, error))
  },
  [LOGOUT] ({ commit }: { commit: Commit }) {
    commit(PURGE_AUTH)
  },
  [CHECK_AUTH] ({ commit }: { commit: Commit }) {
    if (jwtService.getToken()) {
      commit(SET_LOGGED_IN)
      ApiService.setHeader()
    }
  }
}

// mutations
const mutations = {
  [SET_ERROR] (state: any, error: any) {
    state.errors = error
  },
  [SEND_START] (state: { isLoading: boolean }) {
    state.isLoading = true
  },
  [SET_AUTH] (state: any, data: LoginResponseModel) {
    state.isLoading = false
    jwtService.saveToken(data.token)
    jwtService.saveTokenExpiration(data.expiration)
    state.isLoggedIn = true
    router.push('/')
  },
  [PURGE_AUTH] (state: any) {
    jwtService.destroyToken()
    jwtService.destroyTokenExpiration()
    ApiService.removeHeader()
    state.isLoggedIn = false
    router.push('/login')
  },
  [SET_LOGGED_IN] (state: any) {
    state.isLoggedIn = true
  }
}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
}
