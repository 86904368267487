import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import en from 'vuetify/src/locale/en'
import de from 'vuetify/src/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  theme: { dark: true },
  lang: {
    locales: { en, de },
    current: 'de'
  }
})
