import { AuthenticationService } from '@/api/AuthenticationService'
import User from '@/models/UserModel'
import { USERS_FETCH } from '../actions'
import { FETCH_START, FETCH_END, FETCH_ERROR } from '../mutations'

// initial state
const state = {
  users: [],
  isLoadingUsers: false,
  usersCount: 0
}

// getters
const getters = {
  users (state: any) {
    return (typeof state.users === 'undefined') ? [] : state.users
  },
  isLoadingUsers () {
    return state.isLoadingUsers
  }
}

// actions
const actions = {
  [USERS_FETCH] ({ commit }: { commit: any }, params: any) {
    commit(FETCH_START)

    AuthenticationService.users().then(({ data }) => {
      commit(FETCH_END, data)
    }).catch(error => commit(FETCH_ERROR, error))
  }
}

// mutations
const mutations = {
  [FETCH_START] (state: { isLoadingUsers: boolean }) {
    state.isLoadingUsers = true
  },

  [FETCH_END] (state: { users: User[], isLoadingUsers: boolean, userCount: number }, users: Array<User>) {
    state.isLoadingUsers = false
    state.users = users
    state.userCount = users.length
  },

}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
}