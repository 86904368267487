import { NotesService } from '@/api/NotesService'
import NoteModel from '@/models/NoteModel'
import { Commit, Dispatch } from 'vuex'
import { NOTES_FETCH, NOTE_ADD, NOTE_DELETE } from '../actions'
import { FETCH_START, FETCH_END, FETCH_ERROR } from '../mutations'

// initial state
const state = {
  notes: [],
  isLoadingNotes: false,
  notesCount: 0
}

// getters
const getters = {
  notes (state: any) {
    return (typeof state.notes === 'undefined') ? [] : state.notes
  },
  isLoadingNotes () {
    return state.isLoadingNotes
  }
}

// actions
const actions = {
  [NOTES_FETCH] ({ commit }: { commit: Commit }, params: any) {
  commit(FETCH_START)

    NotesService.query({}).then(({ data }) => {
      commit(FETCH_END, data)
    }).catch(error => commit(FETCH_ERROR, error))
  },

  [NOTE_ADD] ({ dispatch }: {commit: Commit; dispatch: Dispatch}, noteText: string) {
    const note = {
      text: noteText,
      timeStamp: new Date()
    }

    NotesService.post(note).then(res => {
      dispatch(NOTES_FETCH)
    }).catch(error => {
      console.error(error)
    })
  },

  [NOTE_DELETE] ({ dispatch }: {commit: Commit; dispatch: Dispatch}, id: string) {
    NotesService.delete(id).then(() => {
      dispatch(NOTES_FETCH)
    }).catch(error => {
      console.error(error)
    })
  }
}

// mutations
const mutations = {
  [FETCH_START] (state: { isLoadingNotes: boolean }) {
    state.isLoadingNotes = true
  },

  [FETCH_END] (state: { notes: NoteModel[]; isLoadingNotes: boolean; notesCount: number }, notes: Array<NoteModel>) {
    state.notes = notes
    state.isLoadingNotes = false
    state.notesCount = notes.length
  },

  [FETCH_ERROR] (state: {isLoadingNotes: boolean}, error: string) {
    state.isLoadingNotes = false
  }

}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
}
