import { ApiService } from './api.service'

export const NotesService = {
  resource: '/api/notes',
  query (params: object) {
    return ApiService.query(this.resource, { params })
  },
  get (slug: string) {
    return ApiService.get(this.resource, slug)
  },
  post (params: object) {
    return ApiService.post(this.resource, params)
  },
  update (slug: string, params: object) {
    return ApiService.update(this.resource, slug, params)
  },
  delete (slug: string) {
    return ApiService.delete(`${this.resource}/${slug}`)
  }
}
