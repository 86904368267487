import LoginModel from '@/models/LoginModel'
import { ApiService } from './api.service'

export const AuthenticationService = {
  resource: '/api/Authentication',
  login (params: LoginModel) {
    return ApiService.post(this.resource + '/login', params)
  },

  users () {
    return ApiService.get(this.resource + '/users')
  }
  // register (params: object) {
  //   return ApiService.post(this.resource + "/register", params)
  // }
}
