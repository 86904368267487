import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import API_URL from './config'
import jwtService from './jwt.service'

Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = API_URL

export const ApiService = {
  AUTHORIZATION: 'Authorization',
  setHeader () {
    Vue.axios.defaults.headers.common[this.AUTHORIZATION] = `Bearer ${jwtService.getToken()}`
  },

  removeHeader () {
    delete Vue.axios.defaults.headers.common[this.AUTHORIZATION]
  },

  query (resource: string, params: object) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[API] QUERY ${error}`)
    })
  },

  get (resource: string, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[API] GET ${error}`)
    })
  },

  post (resource: string, params: object) {
    return Vue.axios.post(`${resource}`, params).catch(error => {
      throw new Error(`[API] POST ${error}`)
    })
  },

  update (resource: string, slug: string, params: object) {
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      throw new Error(`[API] UPDATE ${error}`)
    })
  },

  put (resource: string, params: object) {
    return Vue.axios.put(`${resource}`, params).catch(error => {
      throw new Error(`[API] PUT ${error}`)
    })
  },

  delete (resource: string) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[API] DELETE ${error}`)
    })
  }
}

export default ApiService
