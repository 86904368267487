


















































































import { LOGOUT } from '@/store/actions'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
  name: 'NavigationBar',

  data: () => ({
    drawer: true,
    mini: true,
    list: [
      {
        title: 'views.dashboard.title',
        link: '/dashboard',
        icon: 'mdi-view-dashboard'
      },
      {
        title: 'views.notes.title',
        link: '/notes',
        icon: 'mdi-note-multiple'
      }
    ],
    listBottom: [
      {
        title: 'views.about.title',
        link: '/about',
        icon: 'mdi-information-variant'
      },
      {
        title: 'views.users.title',
        link: '/users',
        icon: 'mdi-account-box-multiple'
      },
      {
        title: 'views.settings.title',
        link: '/settings',
        icon: 'mdi-cog'
      }
    ]
  }),
  methods: {
    logout () {
      this.$store.dispatch(LOGOUT)
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  }
})
